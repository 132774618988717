import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import { useModal } from 'src/hooks';
import { isModalComplete } from 'src/components/ModalDialog';
import DataTableSearchBar from 'src/components/DataTable/components/DataTableSearchBar';
import { FilterTableContext } from 'src/components/DataTable/components/FilterTableContext';
import solutionsStore, { useSolutionAlertRulesList } from '../redux';
import SolutionAlertRulesListAction from './components/SolutionAlertRulesListAction';
import { SolutionAlertRuleItem, SolutionAlertRuleItemField } from '../types';
import SolutionAlertRuleStatus from './components/SolutionAlertRuleStatus';
import SolutionAlertRuleCreateContainer from './components/SolutionAlertRuleCreateContainer';
import { ALERT_RULE_TYPE, ALERT_SEVERITY, ALERT_STATE } from './constant';

const columns = [
  {
    id: SolutionAlertRuleItemField.RuleName,
    title: 'Rule Name',
  },
  {
    id: SolutionAlertRuleItemField.RuleType,
    title: 'Rule Type',
  },
  {
    id: SolutionAlertRuleItemField.Severity,
    title: 'Severity Type',
  },
  {
    id: SolutionAlertRuleItemField.Interval,
    title: 'Checks Every',
  },
  {
    id: SolutionAlertRuleItemField.Enabled,
    title: 'State',
    Component: SolutionAlertRuleStatus,
  },
  getActionsColConfig(SolutionAlertRulesListAction),
];

const filteredColumns = [
  { id: SolutionAlertRuleItemField.RuleType, title: 'Rule Type' },
  { id: SolutionAlertRuleItemField.Severity, title: 'Severity Type' },
  {
    id: SolutionAlertRuleItemField.Enabled,
    title: 'State',
  },
];

const filterMenu = {
  alertType: ALERT_RULE_TYPE,
  severity: ALERT_SEVERITY,
  enabled: ALERT_STATE,
};

export default function SolutionAlertRules() {
  const [data, loading] = useSolutionAlertRulesList();
  const { search } = useTableQueryParams();
  const { solutionId } = useParams();
  const { isPlatformReader } = useUserRoles();
  const actions = useStoreUnitActions(solutionsStore);
  const { alertRules } = useStoreUnitActions(solutionsStore);
  const [showFilters, setShowFilter] = useState(false);
  const [filterString, setFilterStrings] = useState('');
  const [filterData, setFilterData] = useState<SolutionAlertRuleItem[]>([]);
  const rowData = data;

  useEffect(() => {
    actions.alertRules(solutionId);
  }, []);

  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const [CreateNewAlertDialog, showNewAlertDialog] = useModal(
    SolutionAlertRuleCreateContainer,
    {
      onClose: (event, reason) =>
        isModalComplete(reason) && actions.alertRules(solutionId),
    }
  );

  const handleClick = (event) => {
    event.preventDefault();
    setShowFilter((prevs) => !prevs);
  };

  function toggleshowfilter() {
    setShowFilter((prevs) => !prevs);
  }
  function scrollerUpdateWithFilter(filterstrings) {
    setFilterStrings(filterstrings);
  }

  return (
    <FilterTableContext.Provider
      value={{
        showFilters,
        toggleshowfilter,
        scrollerUpdateWithFilter,
        filterData,
        setFilterData,
        rowData,
      }}
    >
      <DataTableWrapper
        searchBar={false}
        actionsPanel={
          <Box>
            <Button
              onClick={handleClick}
              sx={{
                padding: '8px',
                marginRight: '7px',
                marginLeft: '-8px',
              }}
              data-testid="filter-button"
            >
              <FilterListIcon />
            </Button>
            <DataTableSearchBar style={{ paddingRight: '10px' }} />
            <DataTableWrapperActionButton
              startIcon={<Add />}
              onClick={showNewAlertDialog}
            >
              Create New
            </DataTableWrapperActionButton>
          </Box>
        }
      >
        <DataTable
          data={filterData}
          loading={loading}
          columns={columns}
          filteredColumns={filteredColumns}
          filterMenus={filterMenu}
          columnTypes={{
            alertType: 'select',
            severity: 'select',
            enabled: 'select',
          }}
          actionName={actions.alertRules}
        />
        {CreateNewAlertDialog}
      </DataTableWrapper>
    </FilterTableContext.Provider>
  );
}
