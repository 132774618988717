import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import ModalDialog from 'src/components/ModalDialog';
import FieldsSet from 'src/components/FieldsSet';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadItemField,
  SolutionEdgeWorkloadRepositoryType,
} from 'src/pages/Solutions/types/solutionEdgeWorkload';
import SolutionGitRepoStatus from './SiteGitRepoStatus';
import SolutionSiteClusterInfo from './SiteClusterInfo';

type Props = {
  item: SolutionEdgeWorkloadItem;
  open: boolean;
  onClose: () => void;
};

const fields = [
  {
    id: SolutionEdgeWorkloadItemField.Name,
    label: 'Name',
  },
  {
    id: SolutionEdgeWorkloadItemField.Description,
    label: 'Description',
  },
  {
    id: SolutionEdgeWorkloadItemField.RepositoryURL,
    label: 'Repository URL',
  },
  {
    id: SolutionEdgeWorkloadItemField.BranchName,
    label: 'Branch Name',
  },
  {
    id: SolutionEdgeWorkloadItemField.GitAuthentication,
    label: 'Git Authentication',
  },
  {
    id: SolutionEdgeWorkloadItemField.HelmAuthentication,
    label: 'Helm Authentication',
  },
  {
    id: SolutionEdgeWorkloadItemField.TlsCertVerification,
    label: 'TLS Certificate',
  },
  {
    id: SolutionEdgeWorkloadItemField.Path,
    label: 'Path',
  },
  {
    id: SolutionEdgeWorkloadItemField.DeploymentStatusData,
    label: 'Status',
    Component: SolutionGitRepoStatus,
  },
  {
    id: SolutionEdgeWorkloadItemField.SiteClusters,
    label: 'Clusters',
    Component: SolutionSiteClusterInfo,
  },
  {
    id: SolutionEdgeWorkloadItemField.TargetNamespace,
    label: 'Target Namespace',
  },
  {
    id: SolutionEdgeWorkloadItemField.KeepResources,
    label: 'Keeping Resources after deletion',
    Component: (params) => (
      <Typography sx={{ fontSize: '16px' }}>
        {params.value === true ? 'Yes' : 'No'}
      </Typography>
    ),
  },
];

function SolutionEdgeWorkloadViewContainer({ item, open, onClose }: Props) {
  const { solutionId } = useParams();

  return (
    <ModalDialog
      title={'View Git Repository'}
      open={open}
      onClose={onClose}
      onConfirm={onClose}
      showCancelBtn={false}
      confirmBtnText={'Close'}
      maxWidth="lg"
      fullWidth
    >
      <FieldsSet
        data={item}
        fields={fields}
        sx={{
          wordBreak: 'break-word',
          gap: 2,
        }}
      />
    </ModalDialog>
  );
}

export default SolutionEdgeWorkloadViewContainer;
