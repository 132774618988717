import TextField from '@mui/material/TextField';
import compact from 'lodash/compact';
import { Option } from 'src/components/SelectOption';
import ReadonlyField from 'src/components/ReadonlyField';
import { SxProps } from 'src/theme/types';
import Select from 'src/components/Select/Select';
import Switch from 'src/components/Switch';
import * as Yup from 'yup';
import TextFieldWithInfo from 'src/components/TextField';
import {
  SolutionAlertAggType,
  SolutionAlertFilterField,
  SolutionAlertItemField,
} from '../types';
import {
  ALERT_METRIC_AGG_TYPE,
  ALERT_METRIC_AGG_TYPE_ONE,
  ALERT_METRIC_ISVALUE,
  ALERT_METRIC_OF,
  ALERT_RULE_TYPE,
  ALERT_SEVERITY,
  ALERT_TIME_UNITS,
} from './constant';
import SolutionAlertRuleEditFormRow from './components/SolutionAlertEditFormRow';

const inputStyle: SxProps = { width: ({ spacing }) => spacing(56) };

export const getAlertRuleFormFields = (isEditMode: boolean, aggType?: string) =>
  compact([
    {
      name: SolutionAlertItemField.Name,
      label: 'Name',
      Component: TextField,
      placeholder: 'Enter name',
      required: true,
      validation: Yup.string().required('Name is required'),
      sx: inputStyle,
    },
    {
      name: SolutionAlertItemField.Severity,
      label: 'Severity',
      Component: Select,
      placeholder: 'Select the option',
      required: true,
      validation: Yup.string().required('Severity is required'),
      options: ALERT_SEVERITY,
      sx: inputStyle,
    },
    {
      name: SolutionAlertItemField.EnableRule,
      label: 'Enable Rule',
      Component: Switch,
      type: 'checkbox',
      required: false,
      labelStyle: { color: '#7B7B7B', opacity: '80%' },
      sx: inputStyle,
      disabled: isEditMode,
    },
    {
      name: SolutionAlertItemField.AlertRuleType,
      label: 'Alert Rule Type',
      Component: Select,
      placeholder: 'Select the option',
      required: true,
      options: ALERT_RULE_TYPE,
      validation: Yup.string().required('Alert Rule Type is required'),
      sx: inputStyle,
    },
    {
      type: 'heading',
      name: 'Metric Threshold',
      label: 'Metric Threshold',
      showInfoIcon: true,
      infoTitle: 'Alert when the metrics aggregation exceeds the threshold.',
    },
    {
      name: SolutionAlertItemField.WHEN,
      label: 'WHEN',
      Component: Select,
      placeholder: 'Select the option',
      required: true,
      validation: Yup.string().required('When Field is required'),
      options: ALERT_METRIC_AGG_TYPE_ONE,
      sx: inputStyle,
    },
    aggType !== SolutionAlertAggType.CustomEquation && {
      name: SolutionAlertItemField.OF,
      label: 'OF',
      Component: Select,
      placeholder: 'Select the option',
      required: true,
      validation: Yup.string().required('OF Field is required'),
      options: ALERT_METRIC_OF,
      sx: inputStyle,
    },
    {
      name: SolutionAlertItemField.IsValue,
      label: 'Comparator',
      Component: Select,
      placeholder: 'Select the option',
      required: true,
      validation: Yup.string().required('Comparator Field is required'),
      options: ALERT_METRIC_ISVALUE,
      sx: inputStyle,
    },
    {
      name: SolutionAlertItemField.ThresholdValues,
      label: 'Threshold Value',
      Component: TextField,
      placeholder: 'Enter the value',
      type: 'number',
      required: true,
      initialValue: 0,
      validation: Yup.number().required('Threshold Value is required'),
      sx: {
        width: ({ spacing }) => spacing(56),
        marginRight:
          aggType === SolutionAlertAggType.CustomEquation ? '350px' : '0px',
      },
    },
    aggType === SolutionAlertAggType.CustomEquation && {
      name: SolutionAlertItemField.AggregationName,
      label: `Aggregation Name`,
      Component: TextField,
      placeholder: 'Enter a Character',
      required: true,
      inputProps: {
        maxLength: 1,
      },
      validation: Yup.string()
        .length(1)
        .matches(/[A-Z]/, 'Please enter a letter between A and Z')
        .required('Aggregation Name is required'),
      sx: { width: ({ spacing }) => spacing(23) },
    },
    aggType === SolutionAlertAggType.CustomEquation && {
      name: SolutionAlertItemField.AggregationValue,
      label: 'Operation',
      Component: Select,
      placeholder: 'Select the option',
      required: true,
      validation: Yup.string().required('Operation Field is required'),
      options: ALERT_METRIC_AGG_TYPE,
      sx: { width: ({ spacing }) => spacing(37) },
    },
    aggType === SolutionAlertAggType.CustomEquation && {
      name: SolutionAlertItemField.FieldValue,
      label: 'Field',
      Component: Select,
      placeholder: 'Select the option',
      required: true,
      validation: Yup.string().required('Field is required'),
      options: ALERT_METRIC_OF,
      sx: { width: ({ spacing }) => spacing(50) },
    },
    aggType === SolutionAlertAggType.CustomEquation && {
      name: SolutionAlertItemField.CustomMetrics,
      appendLabel: 'Add Aggregation',
      childFields: getSolutionAlertsFilterFields(),
      RowComponent: SolutionAlertRuleEditFormRow,
      maxRows: 4,
      showDefaultRow: true,
    },
    aggType === SolutionAlertAggType.CustomEquation && {
      name: SolutionAlertItemField.Equation,
      label: 'Equation',
      Component: TextFieldWithInfo,
      placeholder: 'Enter the expression',
      required: true,
      validation: Yup.string().required('Equation is required'),
      showinfoicon: true,
      infotitle: 'Supports basic math expressions, Eg: A+B',
      sx: {
        width: ({ spacing }) => spacing(56),
        marginRight: '10px',
      },
    },
    {
      type: 'heading',
      name: 'FOR THE LAST',
      label: 'FOR THE LAST',
      showFieldWidth: true,
      width: ({ spacing }) => spacing(56),
    },
    {
      type: 'heading',
      name: 'Check Every',
      label: 'Check Every',
      showFieldWidth: true,
      width: ({ spacing }) => spacing(56),
    },
    {
      name: SolutionAlertItemField.LastValue,
      label: 'Threshold Value',
      Component: TextField,
      initialValue: 0,
      placeholder: 'Enter the value',
      type: 'number',
      sx: { width: ({ spacing }) => spacing(27) },
    },
    {
      name: SolutionAlertItemField.LastTimeUnits,
      label: 'Time Units',
      Component: Select,
      placeholder: 'Select the option',
      options: ALERT_TIME_UNITS,
      sx: { width: ({ spacing }) => spacing(27) },
    },
    {
      name: SolutionAlertItemField.EveryValue,
      label: 'Threshold Value',
      Component: TextField,
      initialValue: 0,
      placeholder: 'Enter the value',
      type: 'number',
      sx: { width: ({ spacing }) => spacing(27) },
    },
    {
      name: SolutionAlertItemField.EveryTimeUnits,
      label: 'Time Units',
      Component: Select,
      placeholder: 'Select the option',
      options: ALERT_TIME_UNITS,
      sx: { width: ({ spacing }) => spacing(27) },
    },
  ]);

export const getSolutionAlertsFilterFields = () => [
  {
    name: SolutionAlertFilterField.Name,
    label: `Aggregation Name`,
    Component: TextField,
    placeholder: 'Enter a Character',
    inputProps: {
      maxLength: 1,
    },
    validation: Yup.string()
      .length(1)
      .matches(/[A-Z]/, 'Please enter a letter between A and Z')
      .required('Aggregation Name is required'),
    sx: { width: ({ spacing }) => spacing(23) },
  },
  {
    name: SolutionAlertFilterField.AggType,
    label: `Operation `,
    Component: Select,
    placeholder: 'Select the option',
    options: ALERT_METRIC_AGG_TYPE,
    sx: { width: ({ spacing }) => spacing(37) },
  },
  {
    name: SolutionAlertFilterField.Field,
    label: `Field `,
    placeholder: 'Select the value',
    Component: Select,
    options: ALERT_METRIC_OF,
    sx: { width: ({ spacing }) => spacing(50) },
  },
];
