export enum SolutionAlertRuleItemSeverity {
  Minor = '3',
  Critical = '1',
}

export enum SolutionAlertRuleStatus {
  Enabled = 'true',
  Disabled = 'false',
}

export enum SolutionAlertAggType {
  Average = 'avg',
  Maximum = 'max',
  Minimum = 'min',
  Sum = 'sum',
  CustomEquation = 'custom',
}

export type SolutionAlertRuleItem = {
  id: number;
  name: string;
  typeId: string;
  alertType: string;
  severity: SolutionAlertRuleItemSeverity;
  scheduleInterval: number;
  enabled: boolean;
  aggType: string;
  everyTimeUnits: string;
  comparator: string;
  threshold: number;
  timeSize: number;
  timeUnit: string;
  metric: string;
  equation: string;
  customMetrics: any[];
};

export enum SolutionAlertRuleItemField {
  RuleName = 'name',
  RuleType = 'alertType',
  Severity = 'severity',
  Interval = 'interval',
  Enabled = 'enabled',
}

export enum SolutionAlertFilterField {
  Name = 'name',
  Field = 'field',
  AggType = 'aggType',
}

export type SolutionAlertFilter = {
  name: string;
  field: string;
  aggType: string;
};

export enum SolutionAlertItemField {
  Name = 'name',
  Severity = 'severity',
  EnableRule = 'enabled',
  WHEN = 'aggType',
  OF = 'metric',
  ThresholdValues = 'threshold',
  IsValue = 'comparator',
  AggregationName = 'aggregationName',
  AggregationValue = 'aggregationValue',
  FieldValue = 'fieldValue',
  Equation = 'equation',
  LastValue = 'timeSize',
  LastTimeUnits = 'timeUnit',
  EveryValue = 'scheduleInterval',
  EveryTimeUnits = 'everyTimeUnits',
  AlertRuleType = 'alertType',
  CustomMetrics = 'customMetrics',
}
