import { useEffect, useState } from 'react';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useParams } from 'react-router-dom';
import {
  API_PUT_SOLUTION_ALERT_RULES,
  API_WORKLOAD_CONFIG_UPDATE,
} from '../../constants';
import { SolutionAlertRuleItem, SolutionEdgeWorkloadItem } from '../../types';
import solutionsStore, { useCurrentSolutionName } from '../../redux';
import { getSolutionAlertParams } from '../utils';
import SolutionAlertRuleEditModal from './SolutionAlertRuleEditModal';

type Props = {
  item: SolutionAlertRuleItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionAlertRuleEditContainer({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const solutionName = useCurrentSolutionName();
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);

  const [handleSubmit, loading] = useStatefulApi<SolutionAlertRuleItem>(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: API_PUT_SOLUTION_ALERT_RULES,
        params: {
          ruleId: item?.id,
          enabled: params.enabled,
          ...getSolutionAlertParams(params, solutionName, solutionId),
        },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `Alert rule is saved`,
        text: 'Alert rule has been modified successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.alertRules(solutionId);
    }
  );

  return (
    <SolutionAlertRuleEditModal
      item={item}
      title={'Edit Alert Rule'}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmBtnText={'Save'}
      loading={loading}
    />
  );
}

export default SolutionAlertRuleEditContainer;
