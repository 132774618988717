import * as Yup from 'yup';
import FileUploadInput from 'src/components/FileUploadInput';
import { ALLOWED_EXTENSION } from '../SolutionDeviceConfig/constants';

const fileInputField = {
  Component: FileUploadInput,
  type: 'file',
  required: true,
  validation: Yup.mixed()
    .required('A file is required')
    .test(
      'fileFormat',
      ({ value }) =>
        `This file (${value?.name}) type is not supported. Only ${ALLOWED_EXTENSION} are allowed.`,
      (value) => value?.type === ALLOWED_EXTENSION
    ),
};

export const uploadFormFields = [
  {
    name: 'SerializedJsonModel',
    label: 'Upload JSON Files',
    ...fileInputField,
  },
];
