import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_GET_ASSET_EDGEASSET,
  SOLUTIONS_ASSET_EDGE_DETAILS,
  SOLUTIONS_STORE,
} from '../constants';

const api = ({ solutionId, siteId, twinId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_GET_ASSET_EDGEASSET, {
      solutionId,
      siteId,
      twinId,
    }),
  });

const assetEdgeDetails = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_ASSET_EDGE_DETAILS,
  responseMapping: ResponseMapping.Default,
});

export default assetEdgeDetails;
