import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Popover, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import Select from 'src/components/Select';
import { FilterTableContext } from './FilterTableContext';

interface COMPONENTSFILTERINTERFACE {
  index?: string;
  selectedColumn: string;
  searchValue: string;
}

const DataTableFilter = ({
  id,
  columns,
  data,
  anchorEl,
  filteredColumns,
  filterMenus,
  columnTypes,
  actionName,
  ...rest
}) => {
  const filterContext = useContext(FilterTableContext);
  const [init, setInit] = useState(false);
  const [filterGridData, setFilterGridData] = useState(data);

  const [components, setComponents] = useState([
    {
      index: '0',
      selectedColumn: '',
      searchValue: '',
    },
  ]);

  function addFilterInputFields() {
    if (components.length < 3)
      setComponents((prevs) => [
        ...components,
        {
          index: components.length.toString(),
          selectedColumn: '',
          searchValue: '',
        },
      ]);
  }
  const removeFilterInputFields = (i) => {
    const rows = [...components];
    if (rows.length > 1) rows.splice(i, 1);
    setComponents(rows);
  };
  const handleshowFilter = () => {
    filterContext?.toggleshowfilter();
  };
  const handleFilterChange = (updatedComponents) => {
    setComponents([...updatedComponents]);
  };
  const { solutionId } = useParams();

  function filterData(rowData, components) {
    return rowData?.filter((row) =>
      components.every(({ selectedColumn, searchValue }) => {
        if (!selectedColumn || !searchValue) return true;
        const rowValue = row[selectedColumn]?.toString().toLowerCase();
        const searchValueData = searchValue.trim().toLowerCase();
        return rowValue === searchValueData;
      })
    );
  }

  useEffect(() => {
    const newFilterData = filterData(filterContext?.rowData, components);
    setFilterGridData(newFilterData);
  }, [components, filterContext?.rowData]);

  const handleApplyFilter = () => {
    handleshowFilter();
    const filteredData: string[] = [];
    components.forEach((value) => {
      if (columnTypes[value.selectedColumn] === 'select' && value.searchValue) {
        filteredData.push(`${value.selectedColumn} eq ${value.searchValue}`);
      } else {
        filteredData.push(
          `contains(${value.selectedColumn} , '${value.searchValue}')`
        );
      }
    });
    const filterString = filteredData.join('and');
    if (filterString) {
      if (actionName && !filterContext?.filterData) {
        actionName.init({ solutionId, filterString });
        setInit(true);
      } else {
        filterContext?.setFilterData(filterGridData);
      }
    }
    filterContext?.scrollerUpdateWithFilter(filterString);
  };

  const handleResetFilter = () => {
    setComponents([
      ...components.splice(0, 0),
      {
        index: '0',
        selectedColumn: '',
        searchValue: '',
      },
    ]);
    if (init) {
      actionName.init({ solutionId });
    } else {
      actionName(solutionId);
    }
  };

  function renderOptions(selectedColumn: string) {
    return filterMenus?.[selectedColumn];
  }

  const newColumns =
    filteredColumns !== null &&
    filteredColumns?.map((item) => {
      const { id: value, title: label, ...rest } = item;
      const disabled = components.some((x) => x.selectedColumn === value);
      return { value, label, disabled, ...rest };
    });

  const handleColumnChange = (event: any, index) => {
    components[index].selectedColumn = event.target.value;
    components[index].searchValue = '';
    handleFilterChange(components);
  };
  const handleValueChange = (event: any, index) => {
    components[index].searchValue = event.target.value.toString();
    handleFilterChange(components);
  };

  const renderFilterComponent = (
    item: COMPONENTSFILTERINTERFACE,
    index: number
  ) => {
    const columnType = columnTypes?.[item.selectedColumn] || 'textfield';
    if (columnType === 'select') {
      return (
        <Select
          sx={{ width: ({ spacing }) => spacing(26) }}
          value={item.searchValue}
          label="Select Value"
          options={renderOptions(item.selectedColumn)}
          onChange={(event) => handleValueChange(event, index)}
        ></Select>
      );
    }
    return (
      <TextField
        label="Filter Value"
        sx={{ width: ({ spacing }) => spacing(26) }}
        value={item.searchValue}
        onChange={(event) => handleValueChange(event, index)}
        placeholder="FilterValue"
      ></TextField>
    );
  };

  return (
    <Popover
      id={id}
      open={Boolean(filterContext?.showFilters)}
      onClose={handleshowFilter}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ margin: ({ spacing }) => spacing(1) }}>
        {components.map((item, index) => (
          <>
            <Box
              display="flex"
              key={index}
              justifyContent="center"
              alignItems="center"
              sx={{
                margin: ({ spacing }) => spacing(2),
                maxWidth: ({ spacing }) => spacing(40),
              }}
            >
              <Button sx={{ ml: ({ spacing }) => spacing(-3) }}>
                <ClearIcon
                  onClick={() => {
                    removeFilterInputFields(index);
                  }}
                />
              </Button>
              <Select
                sx={{
                  width: ({ spacing }) => spacing(33),
                  mr: ({ spacing }) => spacing(1),
                }}
                label="Select Column"
                value={item.selectedColumn}
                options={newColumns}
                onChange={(event) => handleColumnChange(event, index)}
              ></Select>
              {renderFilterComponent(item, index)}
            </Box>
          </>
        ))}
        <Button
          onClick={addFilterInputFields}
          startIcon={<AddIcon></AddIcon>}
          sx={{
            width: ({ spacing }) => spacing(16),
            ml: ({ spacing }) => spacing(-1),
          }}
        >
          Add Filter
        </Button>
        <Button
          variant="outlined"
          sx={{ ml: ({ spacing }) => spacing(5.1) }}
          onClick={handleResetFilter}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          sx={{ ml: ({ spacing }) => spacing(1) }}
          onClick={handleApplyFilter}
        >
          Apply
        </Button>
      </Box>
    </Popover>
  );
};

export default DataTableFilter;
