import { Box, Typography } from '@mui/material';
import ModalDialog from 'src/components/ModalDialog';

type Props = {
  title: string;
  edgeServiceUrl: string;
  open: boolean;
  onClose: () => void;
};

function SiteEdgeServiceUrlViewModal({
  edgeServiceUrl,
  title,
  open,
  onClose,
}: Props) {
  return (
    <ModalDialog
      open={open}
      maxWidth="sm"
      fullWidth
      title="Edge Service URL"
      titleStyle={{ fontWeight: 500 }}
      onClose={onClose}
      cancelBtnText="Close"
      showConfirmBtn={false}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight={500} sx={{ paddingRight: '8px' }}>
          {' '}
          URL
        </Typography>
        {edgeServiceUrl}
      </Box>
    </ModalDialog>
  );
}

export default SiteEdgeServiceUrlViewModal;
