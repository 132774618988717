import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { generateApiPath } from 'src/utils';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useForm, FormField } from 'src/components/Form';
import {
  pepApiRequest,
  ContentType,
  RequestMethod,
  useStatefulApi,
} from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore, { useSolutionAssetEdgeDetails } from '../../redux';
import { uploadFormFields } from '../formFields';
import { API_POST_ASSET_CONFIG_UPLOAD } from '../../constants';

const formStyle = {
  flexDirection: 'row',
  '.MuiFormControl-root': {
    width: ({ spacing }) => spacing(90),
  },
};

type Props = {
  api: string;
  formFields: FormField[];
  title: string;
  open: boolean;
  onClose: ModalCloseHandler;
  sitesId?: string;
  id?: string;
};

function SolutionAssetUploadModal({ sitesId, id, open, onClose }: Props) {
  const actions = useStoreUnitActions(solutionsStore);
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  // const [data, dataloading] = useSolutionAssetEdgeDetails();

  // useEffect(() => {
  //   if (sitesId) {
  //     actions.assetEdgeDetails({ solutionId, siteId: sitesId, twinId: id })
  //   }
  // }, [sitesId])

  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_POST_ASSET_CONFIG_UPLOAD, {
          solutionId,
          siteId: sitesId,
          twinId: id,
        }),
        contentType: ContentType.FormData,
        params,
      }),
    (res, params) => {
      showAlert({
        type: AlertType.Success,
        title: 'Configuration file uploaded',
        text: `Asset configuration file ${params.SerializedJsonModel.path} is uploaded successfully.`,
      });
      onClose();
    },
    (err) => {
      if (typeof err === 'object' && err?.status === 400) {
        return;
      }
      showAlert({
        type: AlertType.Error,
        title: 'Configuration file not uploaded',
        text: 'Error while uploading JSON.',
      });
    }
  );
  const [formComponent, handleSubmit] = useForm({
    sx: formStyle,
    fields: uploadFormFields,
    onSubmit,
  });

  return (
    <ModalDialog
      title="Upload configuration"
      open={open}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Save"
      loading={loading}
      maxWidth="lg"
    >
      {formComponent}
    </ModalDialog>
  );
}

export default SolutionAssetUploadModal;
