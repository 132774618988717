import { API_COMPANIES } from '../Companies/constants';

// routes
export const NEW_SOLUTION = 'new-:solutionType';
export const SOLUTION_ALERT_RULES = 'alert-rules';
export const SOLUTION_ASSET_HIERARCHY = 'asset-hierarchy';
export const SOLUTION_EDIT = 'edit';
export const SOLUTION_DETAILS = `:solutionId-:solutionType`;
export const SOLUTION_DEVICE_CONFIG = 'device-config';
export const SOLUTION_CONTRACTS = 'contracts';
export const SOLUTION_DEVICES = 'devices';
export const SOLUTION_DEVICE_DETAILS = ':deviceId';
export const SOLUTION_DEVICE_GROUPS = 'device-groups';
export const SOLUTION_JOBS = 'jobs';
export const SOLUTION_RULES_CONFIGURATION = 'rules-configuration';
export const SOLUTION_JOB_DETAILS = ':jobId';
export const SOLUTION_USERS = 'users';
export const SOLUTION_SUBSCRIPTIONS = 'subscriptions';
export const SOLUTION_DASHBOARDS = 'dashboards';
export const SOLUTION_SETTINGS = 'settings';
export const EDGE_WORKLOAD = 'edge-workload';
export const Subscription = 'subscription';
export const Sites = 'sites';
export const SOLUTION_DEVICE_DETAILS_MODULES =
  ':solutionName/:iotEdge/:modelId';
export const SOLUTION_DEVICE_DETAILS_COMPONENTS = ':modelId';

// api
export const API_SOLUTIONS = '/solutions';
export const API_SOLUTIONS_IOTHUB_DEVICE_MODULES = `/management${API_SOLUTIONS}/:solutionId/tenants/:tenantId/ioth/GetDeviceModules`;
export const API_SOLUTIONS_IOTHUB_DEVICE_COMPONENTS = `/model${API_SOLUTIONS}/:solutionId/tenants/1/devicemodels/:modelId/commands/ioth`;
export const API_SOLUTIONS_IOTHUB_COMPONENT_COMMAND = `/management/ioth${API_SOLUTIONS}/:solutionId/tenants/:tenantId/devices/:deviceId/command`;
export const API_SOLUTIONS_IOTHUB_MODULE_LOGS = `/management/ioth${API_SOLUTIONS}/:SolutionId/tenants/:TenantId/devices/:DeviceId/rawcommand`;
export const API_SOLUTION_CREATE = `${API_SOLUTIONS}/create`;
export const API_SOLUTION_CREATE_IOTH = `${API_SOLUTIONS}/IoTH/create`;
export const API_SOLUTION_INDUSTRIAL = `${API_SOLUTIONS}/industrial`;
export const API_SOLUTION_DETAILS = `${API_SOLUTIONS}/:solutionId`;
export const API_SOLUTION_DETAILS_IOTH = `${API_SOLUTIONS}/IoTH/:solutionId`;
export const API_SOLUTION_GET_ADX_TABLES = `${API_SOLUTIONS}/:solutionId/adxtables`;
export const API_SOLUTION_MAPPING_ADX_TABLES = `${API_SOLUTIONS}/:solutionId/mapping`;
export const API_SOLUTION_SET_PERIOD = `${API_SOLUTIONS}/:solutionId/setperiod`;
export const API_SOLUTION_SET_PERIOD_IOTH = `${API_SOLUTIONS}/IoTH/setperiod`;
export const API_SOLUTION_UPDATE_CONSUMER_IOTH = `${API_SOLUTIONS}/:solutionId/IoTH/updatesolution`;
export const API_SOLUTION_UPDATE_CONSUMER = `${API_SOLUTIONS}/:solutionId/updatesolution`;
export const API_SOLUTION_UPDATE_INDUSTRIAL = `${API_SOLUTIONS}/:solutionId/industrial`;
export const API_SOLUTION_UPDATE_STATUS = `${API_SOLUTIONS}/:solutionId/status`;
export const API_SOLUTION_UPDATE_STATUS_IOTH = `${API_SOLUTIONS}/:solutionId/IoTH/status`;
export const API_SOLUTION_MENDER = `${API_SOLUTION_DETAILS}/mender`;
export const API_SOLUTION_CLIENTIDS = `/PlatformRoles/serviceClientIds`;
export const API_SOLUTION_SOURCE_STORAGE = `${API_SOLUTION_DETAILS}/sourceStorage`;
export const API_SOLUTION_NAMESPACE_RSC_QUOTAS_DETAIL = `${API_SOLUTION_DETAILS}/cluster/:clusterId/namespace-resource-quotas`;
export const API_SOLUTION_NOTIFICATION_PREFER = `${API_SOLUTION_DETAILS}/NotificationPreferences`;
export const API_SOLUTION_SITES = `${API_SOLUTIONS}/:solutionId/sites`;
export const API_SOLUTION_SITES_ACTIVE = '/site/activeClusters';
export const API_SOLUTION_SITES_CLUSTERS = `${API_SOLUTION_DETAILS}/siteClusters`;
export const API_SOLUTION_SITES_CLUSTERS_RES_SETTING =
  '/site/:siteId/resourcelimit/:namespaceName';
export const API_SOLUTION_SITES_DELETE = `${API_SOLUTIONS}/:solutionId/sites/unmap`;
export const API_WHITELIST = `${API_SOLUTIONS}/:solutionId/whitelist`;
export const API_OS_WHITELIST = `${API_SOLUTIONS}/:solutionId/oswhitelist`;
const API_DT = `/digitaltwinsmodel/solutions/:solutionId/tenants/:companyId`;
export const API_DT_CONTEXT_MODEL = `${API_DT}/context`;
export const API_DT_DEVICE_MODEL = `${API_DT}/device`;
export const API_DT_DEVICE_MODEL_IOTH = `${API_DT}/device/ioth`;
export const API_DT_CONTEXT_MODEL_ITEM = `${API_DT_CONTEXT_MODEL}/:modelId`;
export const API_DT_DEVICE_MODEL_ITEM = `${API_DT_DEVICE_MODEL}/:modelId`;
export const API_DT_CONTEXT_MODEL_ITEM_IOTH = `${API_DT_CONTEXT_MODEL}/:modelId/ioth`;
export const API_DT_DEVICE_MODEL_ITEM_IOTH = `${API_DT_DEVICE_MODEL}/:modelId/ioth`;
export const API_SOLUTION_COMPANIES = API_COMPANIES;
export const API_SOLUTION_COMPANIES_UPDATE = `${API_COMPANIES}/contracts/:solutionId`;
export const API_DEVICES =
  '/management/solutions/:solutionId/tenants/:companyId/devices';
export const API_DEVICES_IOTH =
  '/management/solutions/:solutionId/tenants/:companyId/ioth/devices';
export const API_DEVICES_DELETE =
  '/management/solutions/:solutionId/devices/decommission';
export const API_DEVICES_DELETE_IOTH =
  '/management/ioth/solutions/:solutionId/devices/decommission';
export const API_DEVICES_MODULES_IOTH =
  '/management/ioth/solutions/:solutionId/tenants/:tenantId/devices/:deviceId/command';
export const API_DEVICES_CERT_DETAILS =
  '/management/solutions/:solutionId/devices/:deviceId';
export const API_DEVICE_CERT_LOGS =
  '/management/solutions/:solutionId/devices/:deviceId/listOfDeviceCertAudit';
export const API_DEVICES_ASSIGN_TEMPLATE =
  '/devices/solutions/:solutionId/devices/:deviceId/template';
export const API_DEVICES_ASSIGN_COMPANY =
  '/devices/solutions/:solutionId/devices/Ioth/organisation';
export const API_DEVICES_OBSERVABILITY_CONFIG =
  '/management/solutions/:solutionId/tenants/:companyId/devices/:deviceId';
export const API_DEVICES_IOTH_DECOMMISIONION =
  '/api/v1/solutions/:solutionId/ioth/status';
export const API_DEVICES_OBSERVABILITY_CONFIG_IOTH_PUT =
  '/management/solutions/:solutionId/tenants/:tenantId/devices/IoTH/:deviceId';
export const API_DEVICES_OBSERVABILITY_CONFIG_IOTH_GET =
  '/management/solutions/:solutionId/tenants/:tenantId/devices/ioth/:deviceId';

export const API_DEVICES_GET_PROPERTIES_VALUES =
  '/management/solutions/:solutionId/tenants/:tenantId/devices/ioth/:deviceId';

export const API_DEVICES_POST_PROPERTIES_VALUES =
  '/management/solutions/:solutionId/tenants/:tenantId/devices/IoTH/:deviceId';

export const API_DEVICES_IOTH_DEPLOY_MANIFEST =
  '/devices/solutions/:solutionId/devices/Ioth/manifest';

export const API_DEVICES_ASSIGN_TEMPLATE_IOTH =
  '/devices/solutions/:solutionId/devices/Ioth/tagModel';
export const API_DEVICES_AUTHORISE = `/management/solutions/:solutionId/devices/setmenderstatus`;
export const API_DEVICES_AUTHORISE_IOTH = `/management/ioth/solutions/:solutionId/devices/setmenderstatus`;
export const API_DEVICE_DEP_RELEASES_LIST = `/ota/solutions/:solutionId/releases/list`;
export const API_DEVICE_DEP_LOG = '/ota/devices/:deviceId/log';
export const API_JOBS = '/jobs/solutions/:solutionId';
export const API_JOBS_IOTH = '/jobs/ioth/solutions/:solutionId';
export const API_DEVICE_DEP_CREATE = `${API_JOBS}/singleDeviceDeployment`;
const API_DEVICE_DEP_JOBS = '/ota/jobs/:jobId';
export const API_DEVICE_DEP_JOB_STATUS = `${API_DEVICE_DEP_JOBS}/statistics`;
export const API_DEVICE_DEP_JOB_LOG = `${API_DEVICE_DEP_JOBS}/log`;
export const API_DEVICE_GROUPS_IOTH =
  '/management/solutions/:solutionId/tenants/:companyId/ioth/devicegroups';
export const API_DEVICE_GROUPS =
  '/management/solutions/:solutionId/tenants/:companyId/devicegroups';
export const API_DEVICE_GROUP_ITEM_IOTH = `${API_DEVICE_GROUPS_IOTH}/:groupId`;
export const API_DEVICE_GROUP_ITEM = `${API_DEVICE_GROUPS}/:groupId`;
export const API_DEVICE_GROUP_DEVICES = `${API_DEVICE_GROUP_ITEM}/devices`;
export const API_DEVICE_GROUP_DEVICES_IOTH = `/management/solutions/:solutionId/tenants/:companyId/devicegroups/:groupId/Ioth/devices`;
export const API_DEVICE_GROUP_PROPS =
  '/model/solutions/:solutionId/tenants/:companyId/deviceTemplates/:modelId/properties';
export const API_DEVICE_GROUP_PROPS_IOTH =
  '/model/solutions/:solutionId/tenants/1/devicemodels/:modelId/properties/ioth';
export const API_JOBS_LIST = `${API_JOBS}/jobs`;
export const API_JOBS_LIST_IOTH = `${API_JOBS_IOTH}/jobs`;
export const API_JOB_ITEM_IOTH = `${API_JOBS_LIST_IOTH}/:jobId`;
export const API_JOB_ITEM = `${API_JOBS_LIST}/:jobId`;
export const API_JOB_ITEM_RERUN = `${API_JOB_ITEM}/rerun`;
export const API_JOB_ITEM_IOTH_RERUN = `${API_JOBS_IOTH}/jobs/:jobId/rerun`;
export const API_JOB_ITEM_RESUME = `${API_JOB_ITEM}/resume`;
export const API_JOB_ITEM_RUN = `/jobs/ioth/jobs/:jobId/run`;
export const API_JOB_ITEM_STOP = `${API_JOB_ITEM}/stop`;
export const API_JOB_ITEM_CANCEL = `${API_JOBS}/ioth/jobs/:jobId/cancel`;

export const API_JOBS_DEVICES_LIST = `${API_JOBS_IOTH}/jobs/:jobId/devices?pageSize=:pageSize&offset=:offset&sequence=:sequence`;
export const API_NAMESPACE_RSC_LIMIT =
  '/rancher/resourcelimit/siteId/:siteId/namespace/:namespaceName';
export const API_GET_SOL_NAMESPACE_RSC_LIMIT = `${API_SOLUTION_DETAILS}/defaultquotalimit`;
export const API_POST_SOL_NAMESPACE_RSC_LIMIT = `${API_SOLUTION_DETAILS}/default-namespace-quota`;
export const API_SOLUTION_USERS = '/platformroles/solutions/:solutionId';
export const API_SOLUTION_USER_ITEM = `${API_SOLUTION_USERS}/:userId`;
export const API_SUBSCRIPTIONS = `/subscription/solutions/:solutionId/subscriptions`;
export const API_SUBSCRIPTIONS_IOTH = `/subscription/solutions/:solutionId/subscriptions/true`;
export const API_GET_FILE_SUBSCRIPTION = `/subscription/solutions/:solutionId/subscriptions/FileSubscription`;
export const API_SAVE_FILE_SUBSCRIPTIONS = `/subscription/solutions/:solutionId/subscriptions/SaveFileStorage`;
export const API_UPDATE_FILE_SUBSCRIPTIONS = `/subscription/solutions/:solutionId/subscriptions/:subscriptionId/UpdateFileStorage`;
export const API_SUBSCRIPTION_ITEM = `${API_SUBSCRIPTIONS}/:subscriptionId`;
export const API_SUBSCRIPTION_ITEM_IOTH = `${API_SUBSCRIPTIONS}/:subscriptionId/true`;
export const API__DELETE_FILE_SUBSCRIPTION_ITEM = `${API_SUBSCRIPTIONS}/:subscriptionId/DeleteFileStorage`;
export const API_GET_ADLS_SUBSCRIPTION = `${API_SUBSCRIPTIONS}/adlssubscription`;
export const API_CREATE_ADLS_SUBSCRIPTIONS = `${API_SUBSCRIPTIONS}/CreateAdlsSubscription`;
export const API_UPDATE_ADLS_SUBSCRIPTIONS = `${API_SUBSCRIPTIONS}/:subscriptionId/UpdateAdlsSubscription`;
export const API__DELETE_ADLS_SUBSCRIPTION_ITEM = `${API_SUBSCRIPTIONS}/:subscriptionId/DeleteAdlsSubscription`;
export const API_GET_SOLUTION_ALERT_RULES = `/SolutionAlertRules/GetSolutionsAlertRules`;
export const API_POST_SOLUTION_ALERT_RULES = `/SolutionAlertRules/CreateSolutionAlertRule`;
export const API_PUT_SOLUTION_ALERT_RULES = `/SolutionAlertRules/UpdateSolutionAlertRule`;
export const API_DELETE_SOLUTION_ALERT_RULES = `/SolutionAlertRules/DeleteSolutionAlertRule`;
export const API_GET_ASSET_DIGITALTWINS = `/digitaltwins/:solutionId/:siteId/asset/:twinId`;
export const API_GET_ASSET_DIGITALTWIN_PROPERTIES = `/digitaltwins/:solutionId/sites/:siteId/twins/:twinId`;
export const API_GET_ASSET_DIGITALTWIN_MODEL = `/digitaltwinsmodel/solutions/:solutionId/industrial/:modelId`;
export const API_GET_ASSET_EDGEASSET = `/digitaltwins/:solutionId/sites/:siteId/edgeasset/:twinId`;
export const API_POST_ASSET_CONFIG_UPLOAD = `/digitaltwinsmodel/solutions/:solutionId/sites/:siteId/asset/:twinId`;
export const API_WORKLOAD_CONFIGURATIONS = `${API_SOLUTION_DETAILS}/GetAllWorkloadConfigurations`;
export const API_WORKLOAD_CONFIG_CREATE = `${API_SOLUTION_DETAILS}/CreateWorkloadConfiguration`;
export const API_WORKLOAD_CONFIG_DELETE = `${API_SOLUTION_DETAILS}/DeleteWorkloadConfiguration/:repoName`;
export const API_WORKLOAD_CONFIG_UPDATE = `${API_SOLUTION_DETAILS}/UpdateWorkloadConfiguration`;
export const API_EDGEWORKLOAD_DEPLOY = `${API_SOLUTION_DETAILS}/updateworkloadconfiguration`;
export const API_EDGEWORKLOAD_DEPLOYMENT_STATUS =
  '/rancher/deployement/repoName/:name';
export const API_SOURCE_IMAGE_REPOSITORY = `/solutions/:solutionId/image-credentials`;
export const API_SOURCE_DOCKER_IMAGES = `${API_SOLUTION_DETAILS}/source-cloud-images`;
export const API_Delete_SOURCE_IMAGE_REPO = `/solutions/image-credentials/:solutionId`;
export const API_PEPSENCE_IMAGE_REPOSITORY = `${API_SOLUTION_DETAILS}/acr`;
export const API_ALL_SOURCE_DOCKER_IMAGES = `${API_SOLUTIONS}/:solutionId/AllSourceDockerImages`;
export const API_SOLUTION_RULES_MANAGEMENT = `/rulesmanagement/rules`;
export const API_SOLUTION_RULES_MANAGEMENT_UPDATE = `/rulesmanagement/rules/:ruleId`;
export const API_SOLUTION_RULES_MANAGEMENT_GET = `/rulesmanagement/rules/solutions/:solutionId`;
export const API_SOLUTION_SPECIFIC_RULES_MANAGEMENT = `${API_SOLUTION_RULES_MANAGEMENT}/:Id`;
export const API_SOLUTION_RULES_MANAGEMENT_ENABLE = `${API_SOLUTION_RULES_MANAGEMENT}?enabled=:enabled`;
export const API_SOLUTION_DASHBOARD_DETAILS = `/dashboard/solutions/:solutionId/dashboarddetails`;
export const API_SOLUTION_DELETE_DASHBOARD = `/dashboard/solutions/:solutionId/deletedashboard`;
export const API_SOLUTION_ANALYSE_DASHBOARD = `/dashboard/solutions/:solutionId/dashboardfunction`;
export const API_SOLUTION_SAVE_DASHBOARD = `/dashboard/solutions/:solutionId/savedashboard`;
export const API_SOLUTION_DASHBOARD_DEVICEGROUPS_COMPANY = `/management/solutions/:solutionId/deviceGroups/:companyId`;
export const API_SOLUTION_COSMOS_SUBSCRIPTION = `${API_SUBSCRIPTIONS}/CosmosSubscription`;
export const API_SOLUTION_COSMOS_SUBSCRIPTION_CREATE = `${API_SUBSCRIPTIONS}/CreateCosmosSubscription`;
export const API_SOLUTION_COSMOS_SUBSCRIPTION_UPDATE = `${API_SUBSCRIPTIONS}/:subscriptionId/UpdateCosmosSubscription`;
export const API_SOLUTION_COSMOS_SUBSCRIPTION_DELETE = `${API_SUBSCRIPTIONS}/:subscriptionId/DeleteCosmosSubscription`;

export const API_SOLUTION_DELETE_CONTRACT = `/companies?solutionId=:solutionId&companyId=:companyId`;

export const API_SOLUTION_TELEMETRY = `/model/solutions/:solutionId/tenants/1/devicemodels/:modelId/telemetry/ioth`;
// redux
export const SOLUTIONS_STORE = 'solutions';
export const SOLUTIONS_STORE_LIST = 'list';
export const SOLUTIONS_STORE_DEVICE_MODULES = 'deviceModules';
export const SOLUTIONS_STORE_RULES_MANAGEMENT = 'rulesManagement';
export const SOLUTIONS_STORE_SPECIFIC_RULES_MANAGEMENT =
  'specificRulesManagement';
export const SOLUTIONS_STORE_ASSET_BULK_DIGITAL_TWINS = 'assetBulkDigitalTwins';
export const SOLUTIONS_ASSET_EDGE_DETAILS = 'assetEdgeDetails';
export const SOLUTIONS_STORE_DEVICE_COMPONENTS = 'deviceComponents';
export const SOLUTIONS_STORE_DASHBOARDS = 'dashboard';
export const SOLUTIONS_STORE_DASHBOARDS_DEVICEGROUPS = 'dashboardDeviceGroups';
export const SOLUTIONS_STORE_ADX_MAPPED_TABLES = 'adxMappedTables';
export const SOLUTIONS_STORE_DETAILS = 'details';
export const SOLUTIONS_STORE_DEVICES = 'devices';
export const SOLUTIONS_STORE_DEVICES_PERSIST = 'devicesPersist';
export const SOLUTIONS_STORE_DEVICES_DECOMISSION = 'devicesDecomission';
export const SOLUTIONS_STORE_DEVICE_BULK_DEPLOYMENT =
  'deviceBulkDeploymentDetails';
export const SOLUTIONS_STORE_DEVICE_DETAILS = 'deviceDetails';
export const SOLUTIONS_STORE_DEVICE_OBSERV_PROPERTIES =
  'deviceObservProperties';
export const SOLUTIONS_STORE_DEVICE_GET_PROPERTIES = 'deviceProperties';
export const SOLUTIONS_STORE_DEVICE_CERT_DETAILS = 'deviceCertificateDetails';
export const SOLUTIONS_STORE_DEVICE_DEP_LOG = 'deviceDeploymentLog';
export const SOLUTIONS_STORE_DEVICE_CERT_AUDIT_LOGS =
  'deviceCertificateAuditLogs';
export const SOLUTIONS_STORE_DEVICE_DEP_RELEASES = 'deviceDeploymentReleases';
export const SOLUTIONS_STORE_DEVICE_CONFIG = 'deviceConfigFile';
export const SOLUTIONS_STORE_JOBS = 'jobs';
export const SOLUTIONS_STORE_JOBS_DEVICES = 'jobsDevices';
export const SOLUTIONS_STORE_DEVICE_GROUPS = 'deviceGroups';
export const SOLUTIONS_STORE_DEVICE_GROUP_DETAILS = 'deviceGroupDetails';
export const SOLUTIONS_STORE_DEVICE_GROUP_PROPERTIES = 'deviceGroupProperties';
export const SOLUTIONS_STORE_EDGE_WORKLOAD_CONFIGURATIONS =
  'edgeWorkloadConfigurations';
export const SOLUTIONS_STORE_SOLUTION_WORKLOAD_STATUS =
  'solutionWorkloadStatus';
export const SOLUTIONS_STORE_FILES_WHITELIST = 'filesWhitelist';
export const SOLUTION_STORE_NAMESPACE_RSC_LIMIT = 'namespaceRscLimit';
export const SOLUTION_STORE_NAMESPACE_RSC_QUOTAS_DETAIL =
  'namespaceRscQuotasDetail';
export const SOLUTION_STORE_CLUSTER_NAMESPACE_RSC_LIMIT = 'clusterRscLimit';
export const SOLUTION_STORE_DEFAULT_NAMESPACE_RSC_LIMIT =
  'defaultNamespaceRscLimit';
export const SOLUTION_STORE_PEPSENCE_IMAGE_REPO_DETAILS =
  'pepsenceImageRepoDetails';
export const SOLUTIONS_STORE_SIGNATURE_WHITELIST = 'signatureWhitelist';
export const SOLUTIONS_STORE_SUBSCRIPTIONS = 'subscriptions';
export const SOLUTIONS_STORE_FILE_SUBSCRIPTION = 'fileSubscriptionById';
export const SOLUTIONS_STORE_ADLS_SUBSCRIPTION = 'adlsSubscriptionById';
export const SOLUTIONS_STORE_ALERT_RULES = 'alertRules';
export const SOLUTIONS_STORE_COSMOS_SUBSCRIPTION = 'cosmosSubscriptionById';
export const SOLUTIONS_STORE_COMPANIES = 'companies';
export const SOLUTIONS_STORE_USERS = 'users';
export const SOLUTIONS_STORE_SITES = 'sites';
export const SOLUTIONS_RULE_TELEMETRY_PROPERTIES = 'ruleTelemtryProperties';

export const Docs =
  '/docs/Industrial/Industrial%20Solution%20Onboarding/SolutionSiteMapping';
