import { SolutionAlertAggType } from '../types';

export const getSolutionAlertParams = (params: any, solName, solId) => {
  const everyTimeUnit = params.everyTimeUnits;
  delete params.everyTimeUnits;
  const aggValue = params.aggregationValue;
  const value = params.fieldValue;
  const aggName = params.aggregationName;

  const customData =
    params.aggType === SolutionAlertAggType.CustomEquation
      ? [
          { name: aggName, aggType: aggValue, field: value },
          ...params.customMetrics,
        ]
      : [];

  return {
    solutionName: solName,
    solutionId: solId,
    solutionNamespace: `${solName}-ns`,
    name: params.name,
    hostname: null,

    schedule: {
      interval: params.scheduleInterval + everyTimeUnit,
    },
    params: {
      criteria: [
        {
          aggType: params.aggType,
          comparator: params.comparator,
          threshold: [params.threshold],
          timeSize: params.timeSize,
          timeUnit: params.timeUnit,
          metric: params.metric,
          customMetrics: customData || [],
          equation: params.equation || '',
        },
      ],
    },
    actions: [
      {
        params: {
          subActionParams: {
            type: params.alertType,
            metricName: params.name,
            severity: params.severity === 'Critical' ? '1' : '3',
          },
        },
      },
    ],
  };
};
