import { getSolutionAlertsFilterFields } from './formFields';

export const ALERT_SEVERITY = [
  { value: 'Minor', label: 'Minor' },
  { value: 'Critical', label: 'Critical' },
];

export const ALERT_STATE = [
  { value: 'true', label: 'Enabled' },
  { value: 'false', label: 'Disabled' },
];

export const ALERT_METRIC_AGG_TYPE = [
  { value: 'avg', label: 'Average' },
  { value: 'max', label: 'Maximum' },
  { value: 'min', label: 'Minimum' },
  { value: 'sum', label: 'Sum' },
];

export const ALERT_METRIC_AGG_TYPE_ONE = [
  { value: 'avg', label: 'Average' },
  { value: 'max', label: 'Maximum' },
  { value: 'min', label: 'Minimum' },
  { value: 'sum', label: 'Sum' },
  { value: 'custom', label: 'Custom Equation' },
];

export const ALERT_METRIC_OF = [
  {
    value: 'kubernetes.container.cpu.usage.limit.pct',
    label: 'kubernetes.container.cpu.usage.limit.pct',
  },
  {
    value: 'kubernetes.container.cpu.usage.node.pct',
    label: 'kubernetes.container.cpu.usage.node.pct',
  },
  {
    value: 'kubernetes.container.memory.available.bytes',
    label: 'kubernetes.container.memory.available.bytes',
  },
  {
    value: 'kubernetes.container.memory.rss.bytes',
    label: 'kubernetes.container.memory.rss.bytes',
  },
  {
    value: 'kubernetes.container.memory.workingset.bytes',
    label: 'kubernetes.container.memory.workingset.bytes',
  },
  {
    value: 'kubernetes.container.memory.usage.bytes',
    label: 'kubernetes.container.memory.usage.bytes',
  },
  {
    value: 'kubernetes.container.memory.usage.limit.pct',
    label: 'kubernetes.container.memory.usage.limit.pct',
  },
  {
    value: 'kubernetes.container.memory.usage.node.pct',
    label: 'kubernetes.container.memory.usage.node.pct',
  },
  {
    value: 'kubernetes.container.status.restarts',
    label: 'kubernetes.container.status.restarts',
  },
  {
    value: 'kubernetes.container.rootfs.available.bytes',
    label: 'kubernetes.container.rootfs.available.bytes',
  },
  {
    value: 'kubernetes.container.rootfs.capacity.bytes',
    label: 'kubernetes.container.rootfs.capacity.bytes',
  },
  {
    value: 'kubernetes.container.rootfs.used.bytes',
    label: 'kubernetes.container.rootfs.used.bytes',
  },
  {
    value: 'prometheus.metrics.kuiper_rule_status',
    label: 'prometheus.metrics.kuiper_rule_status',
  },
  { value: 'prometheus.labels.ruleID', label: 'prometheus.labels.ruleID' },
  {
    value: 'prometheus.metrics.kuiper_sink_process_latency_us',
    label: 'prometheus.metrics.kuiper_sink_process_latency_us',
  },
  {
    value: 'prometheus.metrics.kuiper_sink_records_in_total',
    label: 'prometheus.metrics.kuiper_sink_records_in_total',
  },
  {
    value: 'prometheus.metrics.kuiper_sink_records_out_total',
    label: 'prometheus.metrics.kuiper_sink_records_out_total',
  },
  {
    value: 'prometheus.metrics.kuiper_sink_buffer_length',
    label: 'prometheus.metrics.kuiper_sink_buffer_length',
  },
  {
    value: 'prometheus.metrics.kuiper_source_process_latency_us',
    label: 'prometheus.metrics.kuiper_source_process_latency_us',
  },
  {
    value: 'prometheus.metrics.kuiper_source_records_in_total',
    label: 'prometheus.metrics.kuiper_source_records_in_total',
  },
  {
    value: 'prometheus.metrics.kuiper_source_records_out_total',
    label: 'prometheus.metrics.kuiper_source_records_out_total',
  },
  {
    value: 'prometheus.metrics.kuiper_sink_exceptions_total',
    label: 'prometheus.metrics.kuiper_sink_exceptions_total',
  },
  {
    value: 'kubernetes.pod.cpu.usage.limit.pct',
    label: 'kubernetes.pod.cpu.usage.limit.pct',
  },
  {
    value: 'kubernetes.pod.cpu.usage.nanocores',
    label: 'kubernetes.pod.cpu.usage.nanocores',
  },
  {
    value: 'kubernetes.pod.cpu.usage.node.pct',
    label: 'kubernetes.pod.cpu.usage.node.pct',
  },
  {
    value: 'kubernetes.pod.memory.available.bytes',
    label: 'kubernetes.pod.memory.available.bytes',
  },
  {
    value: 'kubernetes.pod.memory.rss.bytes',
    label: 'kubernetes.pod.memory.rss.bytes',
  },
  {
    value: 'kubernetes.pod.memory.working_set.bytes',
    label: 'kubernetes.pod.memory.working_set.bytes',
  },
  {
    value: 'kubernetes.pod.memory.usage.bytes',
    label: 'kubernetes.pod.memory.usage.bytes',
  },
  {
    value: 'kubernetes.pod.memory.usage.limit.pct',
    label: 'kubernetes.pod.memory.usage.limit.pct',
  },
  {
    value: 'kubernetes.pod.memory.usage.node.pct',
    label: 'kubernetes.pod.memory.usage.node.pct',
  },
  {
    value: 'kubernetes.pod.network.rx.bytes',
    label: 'kubernetes.pod.network.rx.bytes',
  },
  {
    value: 'kubernetes.pod.network.rx.errors',
    label: 'kubernetes.pod.network.rx.errors',
  },
  {
    value: 'kubernetes.pod.network.tx.bytes',
    label: 'kubernetes.pod.network.tx.bytes',
  },
  {
    value: 'kubernetes.pod.network.tx.errors',
    label: 'kubernetes.pod.network.tx.errors',
  },
];

export const ALERT_METRIC_ISVALUE = [
  { value: '>', label: 'IS ABOVE' },
  { value: '<', label: 'IS BELOW' },
  { value: '>=', label: 'IS ABOVE or Equal' },
  { value: '<=', label: 'IS BELOW or Equal' },
  { value: 'between', label: 'IS BETWEEN' },
  { value: 'notBetween', label: 'IS NOT BETWEEN' },
];

export const ALERT_TIME_UNITS = [
  { value: 's', label: 'Seconds' },
  { value: 'm', label: 'Minutes' },
  { value: 'h', label: 'Hours' },
  { value: 'd', label: 'Days' },
];

export const ALERT_RULE_TYPE = [
  { value: 'CPU', label: 'CPU' },
  { value: 'Memory', label: 'Memory' },
  { value: 'Network', label: 'Network' },
  { value: 'Storage', label: 'Storage' },
  { value: 'Latency', label: 'Latency' },
  { value: 'PODMemory', label: 'PODMemory' },
  { value: 'ReceivedDataBandwidth', label: 'ReceivedDataBandwidth' },
  { value: 'TransferredData', label: 'TransferredData' },
  { value: 'ProduceErrors', label: 'ProduceErrors' },
  { value: 'PacketLoss', label: 'PacketLoss' },
  { value: 'STATUSRESTART', label: 'STATUSRESTART' },
  { value: 'Health Check', label: 'Health Check' },
];
