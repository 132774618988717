import { FormArrayRow, FormArrayRowProps } from 'src/components/Form';
import { getSolutionAlertsFilterFields } from '../formFields';

function SolutionAlertRuleEditFormRow({
  childFields,
  update,
  values,
  ...rest
}: FormArrayRowProps) {
  return (
    <FormArrayRow childFields={getSolutionAlertsFilterFields()} {...rest} />
  );
}

export default SolutionAlertRuleEditFormRow;
