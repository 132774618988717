import { useEffect, useRef, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import { Box } from '@mui/system';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import PageCardLayout, { PageCardLoader } from 'src/components/PageCardLayout';
import SelectTreeView from 'src/components/SelectTreeView/SelectTreeView';
import { pepApiRequest, RequestMethod } from 'src/services';
import { isModalComplete } from 'src/components/ModalDialog';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
} from 'src/components/DataTable';
import { Add, Upload } from '@mui/icons-material';
import { useModal } from 'src/hooks';
import SolutionAssetUploadModal from './components/SolutionAssetUploadModal';
import solutionsStore, {
  useSolutionAssetBulkDigitalTwins,
  useSolutionDetails,
} from '../redux';
import {
  API_GET_ASSET_DIGITALTWIN_MODEL,
  API_GET_ASSET_DIGITALTWIN_PROPERTIES,
} from '../constants';
import { SolutionAssetBulkDigitalTwinsItem } from '../types';

interface DisplayData {
  key: string;
  value: any;
}

const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '10px 20px 10px 0px',
};
const LabelStyle = {
  fontWeight: 'bold',
  width: '23%',
};

const columns = [
  {
    id: 'name',
    title: 'Name',
  },
  {
    id: 'timestamp',
    title: 'Timestamp',
  },
  {
    id: 'createdBy',
    title: 'Created by',
  },
];

export default function SolutionAssetHierarchy() {
  const [data, loading] = useSolutionDetails();
  const [assetBulkDigitalTwinsData, digitalTwinDataLoading] =
    useSolutionAssetBulkDigitalTwins();
  const { solutionId } = useParams();
  const { isPlatformReader } = useUserRoles();
  const actions = useStoreUnitActions(solutionsStore);
  const [siteIds, setSiteIds] = useState<string[]>([]);
  const [loadingPropertyDetails, setLoadingPropertyDetails] =
    useState<boolean>(false);
  const [displayData2, setDisplayData2] = useState<DisplayData[]>([]);
  const prevSiteIdsRef = useRef<string[]>([]);
  const updateData = [];
  const [sitesId, setSiteId] = useState('');
  const [id, setId] = useState('');

  const handleSelect = (node: SolutionAssetBulkDigitalTwinsItem) => {
    setLoadingPropertyDetails(true);
    const { siteId, level, id } = node;
    if (siteId && level >= 1) {
      setSiteId(siteId);
      setId(id);
      pepApiRequest({
        method: RequestMethod.Get,
        url: generatePath(API_GET_ASSET_DIGITALTWIN_PROPERTIES, {
          solutionId,
          siteId,
          twinId: id,
        }),
      }).then((response) => {
        const newAssetProperties = response;

        const modelId = newAssetProperties?.$metadata?.$model;
        if (modelId) {
          pepApiRequest({
            method: RequestMethod.Get,
            url: generatePath(API_GET_ASSET_DIGITALTWIN_MODEL, {
              solutionId,
              modelId,
            }),
          }).then((result) => {
            const newDigitalTwinModel = result[0];

            const displayData: DisplayData[] = Object.entries(
              newAssetProperties
            )
              .filter(
                ([key]) =>
                  key !== '$etag' &&
                  key !== '$metadata' &&
                  key !== 'solutionId' &&
                  key !== 'siteID' &&
                  key !== '$dtId' &&
                  key !== 'uId'
              )
              .reduce<DisplayData[]>((acc, [key, value]) => {
                const content = newDigitalTwinModel?.contents?.find(
                  (content) => content.name === key.replace('$', '')
                );

                if (content) {
                  acc.push({
                    key: content.displayName || key.replace('$', ''),
                    value,
                  });
                } else {
                  acc.push({
                    key: key.replace('$', ''),
                    value,
                  });
                }

                return acc;
              }, []);

            setDisplayData2(displayData);
            setLoadingPropertyDetails(false);
          });
        } else {
          setDisplayData2([]);
          setLoadingPropertyDetails(false);
        }
      });
    } else {
      setDisplayData2([]);
      setLoadingPropertyDetails(false);
    }
  };

  useEffect(() => {
    if (data) {
      const ids = data.siteClusters.map((item) => item.siteId);
      setSiteIds(ids);
    }
  }, [data]);

  useEffect(() => {
    const prevSiteIds = prevSiteIdsRef.current;
    const isDifferent = JSON.stringify(prevSiteIds) !== JSON.stringify(siteIds);
    if (isDifferent) {
      actions.assetBulkDigitalTwins({ solutionId, siteIds, twinId: 'PepsiCo' });
      prevSiteIdsRef.current = siteIds;
    }
  }, [solutionId, siteIds]);

  const [UploadNewAssetDialog, showNewUploadAssetDialog] = useModal(
    SolutionAssetUploadModal,
    {
      sitesId,
      id,
      onClose: (event, reason) => isModalComplete(reason),
    }
  );

  return (
    <Box sx={{ flexGrow: 1, padding: 0, minHeight: '60vh' }}>
      <Grid container>
        <Grid item xs={12} md={3}>
          <PageCardLayout
            id="asset-hierarchy-tree-view"
            sx={{ height: '100%' }}
          >
            <SelectTreeView
              items={assetBulkDigitalTwinsData}
              onItemClick={handleSelect}
            />
          </PageCardLayout>
        </Grid>

        <Grid item xs={12} md={9}>
          <PageCardLayout
            id="asset-hierarchy-details"
            sx={{ height: '100%', minHeight: '60vh' }}
          >
            <Typography
              variant="body1"
              style={{
                fontSize: '18px',
                fontWeight: 500,
                fontFamily: 'Stolzl',
                color: '#242424',
              }}
            >
              Asset Properties
            </Typography>
            &nbsp; &nbsp;
            <Table>
              <TableBody>
                {displayData2.length > 0 ? (
                  displayData2.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={[cellStyle, LabelStyle]}>
                        {item.key}
                      </TableCell>
                      <TableCell sx={cellStyle}>{item.value}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body2"
                        color="#A5A5A5"
                        align="center"
                      >
                        No Properties to display
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {loadingPropertyDetails && <PageCardLoader />}
            <br />
            <DataTableWrapper
              title="Asset Configurations"
              titleStyle={{
                fontSize: '18px',
                fontWeight: 500,
                fontFamily: 'Stolzl',
                color: '#242424',
              }}
              actionsPanel={
                <DataTableWrapperActionButton
                  startIcon={<Upload />}
                  onClick={showNewUploadAssetDialog}
                  disabled={isPlatformReader}
                >
                  Upload
                </DataTableWrapperActionButton>
              }
            >
              <DataTable
                data={updateData}
                loading={loading}
                columns={columns}
              />
            </DataTableWrapper>
          </PageCardLayout>
        </Grid>
      </Grid>
      {(loading || digitalTwinDataLoading) && <PageCardLoader />}
      {UploadNewAssetDialog}
    </Box>
  );
}
