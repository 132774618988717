import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  SOLUTIONS_STORE,
  API_GET_ASSET_DIGITALTWINS,
  SOLUTIONS_STORE_ASSET_BULK_DIGITAL_TWINS,
} from '../constants';
import { mapAssetBulkDigitalTwingsData, addSiteId } from './utils';

const api = ({ solutionId, siteIds, twinId }) => {
  const requests = siteIds.map((siteId) =>
    pepApiRequest({
      method: RequestMethod.Get,
      url: generatePath(API_GET_ASSET_DIGITALTWINS, {
        solutionId,
        siteId,
        twinId,
      }),
    }).then((response) => addSiteId(response, siteId))
  );
  return Promise.all(requests).then(mapAssetBulkDigitalTwingsData);
};

const assetBulkDigitalTwins = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_ASSET_BULK_DIGITAL_TWINS,
  responseMapping: ResponseMapping.Default,
});

export default assetBulkDigitalTwins;
