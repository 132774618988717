import { RouteProps } from 'src/router';
import { UserRole } from 'src/auth';
import * as ROUTES from '../constants';
import SolutionAlertRules from '../SolutionAlertRules';
import SolutionDetailTab from './components/SolutionDetailsTab';
import SolutionDeviceConfigTab from './components/SolutionDeviceConfigTab';
import SolutionEdgeWorkloadTab from './components/SolutionEdgeWorkloadTab';
import SolutionCompanies from '../SolutionCompanies';
import SolutionDevices, { solutionDevicesRoutes } from '../SolutionDevices';
import SolutionDeviceGroups from '../SolutionDeviceGroups';
import SolutionJobs, { solutionJobsRoutes } from '../SolutionJobs';
import SolutionUsers from '../SolutionUsers';
import SolutionSubscriptions from '../SolutionSubscriptions';
import EditSolution from '../EditSolution';
import SolutionSettings from '../SolutionSettings';
import SolutionSites from '../SolutionSites';
import RuleConfigurationList from '../ruleConfiguration/ruleConfigurationList';
import SolutionDashboards from '../SolutionDashboards/SolutionDashboards';
import SolutionAssetHierarchy from '../SolutionAssetHierarchy';

export const routes: RouteProps[] = [
  {
    index: true,
    component: SolutionDetailTab,
    meta: {
      title: 'Details',
    },
  },
  {
    path: ROUTES.SOLUTION_EDIT,
    component: EditSolution,
    allowedUserRoles: [
      UserRole.PlatformOwner,
      UserRole.PlatformAdmin,
      UserRole.SolutionOwner,
      UserRole.SolutionUser,
      UserRole.PlatformUser,
      UserRole.PlatformEngineer,
    ],
    meta: {
      hideTabs: true,
      title: 'Edit Solution',
    },
  },
  {
    path: ROUTES.SOLUTION_DEVICE_CONFIG,
    component: SolutionDeviceConfigTab,
    meta: {
      title: 'Device configuration',
    },
  },
  {
    path: ROUTES.SOLUTION_CONTRACTS,
    component: SolutionCompanies,
    meta: {
      title: 'Contracts',
    },
  },
  {
    path: ROUTES.SOLUTION_DEVICES,
    component: SolutionDevices,
    children: solutionDevicesRoutes,
    meta: {
      hideTabsInChildren: true,
      title: 'Devices',
    },
  },
  {
    path: ROUTES.SOLUTION_DEVICE_GROUPS,
    component: SolutionDeviceGroups,
    meta: {
      title: 'Device groups',
    },
  },
  {
    path: ROUTES.SOLUTION_RULES_CONFIGURATION,
    component: RuleConfigurationList,

    meta: {
      title: 'Rule configuration',
    },
  },
  {
    path: ROUTES.SOLUTION_JOBS,
    component: SolutionJobs,
    children: solutionJobsRoutes,
    meta: {
      hideTabsInChildren: true,
      title: 'Jobs',
    },
  },
  {
    path: ROUTES.SOLUTION_DASHBOARDS,
    component: SolutionDashboards,
    meta: {
      title: 'Dashboards',
    },
  },
  {
    path: ROUTES.Sites,
    component: SolutionSites,
    meta: {
      title: 'Sites',
    },
  },
  {
    path: ROUTES.EDGE_WORKLOAD,
    component: SolutionEdgeWorkloadTab,
    meta: {
      title: 'Edge Workload',
    },
  },
  {
    path: ROUTES.SOLUTION_SUBSCRIPTIONS,
    component: SolutionSubscriptions,
    meta: {
      title: 'Subscriptions',
    },
  },
  {
    path: ROUTES.SOLUTION_USERS,
    component: SolutionUsers,
    allowedUserRoles: [
      UserRole.PlatformOwner,
      UserRole.PlatformAdmin,
      UserRole.SolutionOwner,
      UserRole.PlatformReader,
      UserRole.PlatformEngineer,
    ],
    meta: {
      title: 'Users',
    },
  },
  {
    path: ROUTES.SOLUTION_ASSET_HIERARCHY,
    component: SolutionAssetHierarchy,
    allowedUserRoles: [
      UserRole.PlatformOwner,
      UserRole.PlatformAdmin,
      UserRole.SolutionOwner,
      UserRole.PlatformReader,
      UserRole.PlatformEngineer,
    ],
    meta: {
      title: 'Asset Hierarchy',
    },
  },
  {
    path: ROUTES.SOLUTION_ALERT_RULES,
    component: SolutionAlertRules,
    allowedUserRoles: [
      UserRole.PlatformOwner,
      UserRole.PlatformAdmin,
      UserRole.SolutionOwner,
      UserRole.PlatformReader,
      UserRole.PlatformEngineer,
    ],
    meta: {
      title: 'Alert Rules',
    },
  },
  {
    path: ROUTES.SOLUTION_SETTINGS,
    component: SolutionSettings,
    allowedUserRoles: [
      UserRole.PlatformOwner,
      UserRole.PlatformAdmin,
      UserRole.SolutionOwner,
      UserRole.PlatformReader,
      UserRole.PlatformEngineer,
    ],
    meta: {
      title: 'Settings',
    },
  },
  // {
  //   path: ROUTES.Docs,
  //   component: Docs,
  //   meta: {
  //     title: 'Subscriptions',
  //   },
  // },
];
