import { useParams } from 'react-router-dom';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { useAlert, AlertType } from 'src/components/AlertsProvider';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { API_POST_SOLUTION_ALERT_RULES } from '../../constants';
import SolutionAlertRuleEditModal from './SolutionAlertRuleEditModal';
import { getSolutionAlertParams } from '../utils';
import { useCurrentSolutionName } from '../../redux';
import { SolutionAlertRuleItem } from '../../types';

type Props = {
  item: SolutionAlertRuleItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionAlertRuleCreateContainer({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const solutionName = useCurrentSolutionName();

  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: API_POST_SOLUTION_ALERT_RULES,
        params: {
          enabled: params.enabled,
          ...getSolutionAlertParams(params, solutionName, solutionId),
        },
      }),
    (params) => {
      showAlert({
        type: AlertType.Success,
        title: 'Alert rule created',
        text: `${params.name} is created for the ${solutionName} successfully.`,
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionAlertRuleEditModal
      title="Create new rule"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}

export default SolutionAlertRuleCreateContainer;
