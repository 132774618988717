import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore from '../../redux';
import {
  API_DELETE_SOLUTION_ALERT_RULES,
  API_WORKLOAD_CONFIG_DELETE,
} from '../../constants';
import { SolutionAlertRuleItem, SolutionEdgeWorkloadItem } from '../../types';

type Props = {
  item: SolutionAlertRuleItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionAlertRuleDeleteModal({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const ruleId = item?.id;
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [handleDelete, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: API_DELETE_SOLUTION_ALERT_RULES,
        queryParams: { ruleId },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${item?.name} is deleted`,
        text: 'Alert Rule has been deleted successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.alertRules(solutionId);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Alert Rule"
      loading={loading}
      onClose={onClose}
      onConfirm={handleDelete}
      cancelBtnText="No"
      confirmBtnText="Yes"
      maxWidth="sm"
      fullWidth
    >
      Are you sure you want to delete the Alert Rule?<br></br>
      <b>{item?.name}</b>
    </ModalDialog>
  );
}

export default SolutionAlertRuleDeleteModal;
