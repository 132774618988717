import { createContext } from 'react';

interface FilterTableInterface {
  showFilters?: boolean | false;
  toggleshowfilter: any;
  scrollerUpdateWithFilter: any;
  solutionType?: string;
  filterData?: any[];
  setFilterData?: any;
  actionName?: any;
  rowData?: any[];
}

export const FilterTableContext = createContext<FilterTableInterface | null>(
  null
);
