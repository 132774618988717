import { useParams } from 'react-router-dom';
import { DataTableActions } from 'src/components/DataTable';
import { useModal } from 'src/hooks';
import { isModalComplete } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import SolutionAlertRuleEditContainer from './SolutionAlertRuleEditContainer';
import solutionsStore from '../../redux';
import { SolutionAlertRuleItem } from '../../types';
import SolutionAlertDeleteModal from './SolutionAlertDeleteModal';

type Props = {
  item?: SolutionAlertRuleItem;
};

function SolutionAlertRulesListAction({ item }: Props) {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { isSolutionOwner, isPlatformOwner } = useUserRoles();
  const onClose = (reason) =>
    isModalComplete(reason) && actions.alertRules(solutionId);
  const [deleteDialog, showDeleteDialog] = useModal(SolutionAlertDeleteModal, {
    item,
    onClose,
  });

  const [editDialog, showEditDialog] = useModal(
    SolutionAlertRuleEditContainer,
    {
      item,
      onClose,
    }
  );

  return (
    <DataTableActions
      canEdit={isPlatformOwner || isSolutionOwner}
      canDelete={
        (isPlatformOwner || isSolutionOwner) &&
        !item?.name.includes('pep-eiotp')
      }
      onEdit={showEditDialog}
      onDelete={showDeleteDialog}
      showDelete={true}
    >
      {editDialog}
      {deleteDialog}
    </DataTableActions>
  );
}

export default SolutionAlertRulesListAction;
